export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $i18n } = useNuxtApp()

  // console.log(`[全域中間件] to: ${to.path}, from: ${from.path}`)

  const rolesInfo = useRolesInfo()
  const userInfo = useUserInfo()
  const oauthInfo = useOauthInfo()

  const token = localStorage.getItem('token')
  const tenantId = localStorage.getItem('tenantId')

  const getUserPermissionsFn = async () => {
    try {
      // 確認租戶id
      if (!tenantId) {
        await userInfo.getUserInfo()
      }

      // 請求權限
      const permissions = await rolesInfo.getUserPermissions()
      return permissions
    } catch (error) {
      ElMessage.error({ message: $i18n.t('login.getPermissionError') })
      console.error('error:', error.message)
      return null
    }
  }

  // 未登入
  if (!token) {
    if (to.path !== '/login') {
      return navigateTo('/login')
    }
    oauthInfo.resetStore()
  }

  // 已登入
  if (token) {
    if (to.path === '/login') {
      return navigateTo('/')
    } else {
      let menuData = rolesInfo.routeMiddleware
      if (menuData.length === 0) {
        // 若沒有權限則請求權限
        menuData = await getUserPermissionsFn()
        if (!menuData) {
          localStorage.removeItem('token')
          return navigateTo('/login')
        }
      }

      // 取得可訪問路由
      const allowedRoutes = menuData?.filter(item => item?.url).map(item => item.url)

      // 若沒有可訪問路由，則跳轉403
      const permissions = allowedRoutes.length === 0 ||
      (
        (!allowedRoutes.includes(to.path) &&
        to.path !== '/errorPage/403') &&
        to.path !== '/profile' && to.path !== '/' &&
        !to.path.startsWith('/setup/webAccess')
      )

      if (permissions) {
        return navigateTo('/errorPage/403')
      }

      // 確保 `/` 跳轉到第一筆有權限的頁面
      if (to.path === '/' && allowedRoutes[0] && to.path !== allowedRoutes[0]) {
        localStorage.setItem('currentPage', allowedRoutes[0].split('/')[1])
        return navigateTo(allowedRoutes[0])
      }
      // 設置頁預設跳轉第一筆
      if (to.path === '/setup' && rolesInfo.setupMenuRouter.length > 0) {
        const defaultUrl = rolesInfo.setupMenuRouter[0].children[0].url
        return navigateTo(defaultUrl)
      }
    }
  }
})
